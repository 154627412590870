exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-directory-[year]-tsx": () => import("./../../../src/pages/directory/[year].tsx" /* webpackChunkName: "component---src-pages-directory-[year]-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-indexx-tsx": () => import("./../../../src/pages/indexx.tsx" /* webpackChunkName: "component---src-pages-indexx-tsx" */),
  "component---src-pages-mdx-frontmatter-order-tsx": () => import("./../../../src/pages/{mdx.frontmatter__order}.tsx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-order-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */)
}

